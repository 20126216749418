import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 'progress', 'item' ]

  connect() {
    this.updateProgressBar();
  }

  approve(event) {
    let item = event.currentTarget;
    item.dataset.value = 'approved';
    this.updateProgressBar();
  }

  disapprove(event) {
    let item = event.currentTarget;
    item.dataset.value = 'disapproved';
    this.updateProgressBar();
  }

  updateProgressBar() {
    let totalItems = this.itemTargets.length;
    let approvedItems = this.itemTargets.filter(el => el.dataset.value == 'approved').length;

    let percentage = totalItems === 0 ? 0 : (approvedItems / totalItems) * 100;
    this.progressTarget.style.width = `${percentage}%`;
  }
}
