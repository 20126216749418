import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="comment-reply"
export default class extends Controller {
  static targets = ["form"]

  show(event) {
    console.log(this.formTarget)
    event.preventDefault()
    this.formTarget.classList.toggle("hidden")
  }
}