import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "slideover" ]

  connect() {
    this.visible = false
    document.addEventListener("keydown", (event) => {
        if (event.key === "Escape" && this.visible) {
          this.close()
        }
    })  
  }

  disconnect() {
    if (this.visible) {
      this.close()
    }
  }

  open(e) {
    if (e.target.closest('.no-slideover')) {
        return;
    }

    if (!this.visible) {
        this.visible = true
        this.openSlideover()
    }

    let target = e.target;
      while (target && !target.dataset.questionUrl) {
      target = target.parentElement;
    }
    if (target && target.dataset.questionUrl) {
        fetch(target.dataset.questionUrl)
            .then(response => response.text())
            .then(html => {
                const parser = new DOMParser();
                const htmlDocument = parser.parseFromString(html, 'text/html');
                const modalContent = htmlDocument.querySelector('#slideover_content').innerHTML;
    
                document.getElementById("slideover_content").innerHTML = modalContent;
            });
    }
    document.addEventListener("submit:success", () => {
      this.close()
    }, { once: true })
  }

  close() {
    this.visible = false
    this.closeSlideover()
  }

  openSlideover() {
    this.slideoverTarget.classList.remove("-right-full")
    this.slideoverTarget.classList.remove("lg:-right-1/3")
    this.slideoverTarget.classList.add("right-0")
  }

  closeSlideover() {
    this.slideoverTarget.classList.remove("right-0")
    this.slideoverTarget.classList.add("-right-full")
    this.slideoverTarget.classList.add("lg:-right-1/3")
  }
}