import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "form", "sortingField", "ascdescField", "defaultAscDesc", "defaultSortingCriteria", "arrow", "dropdown"]

    connect() {
        this.sortingCriteria = this.defaultSortingCriteriaTarget.getAttribute("value")
        this.ascdesc = this.defaultAscDescTarget.getAttribute("value")
    }

    sort(e) {
        this.sortingCriteria = e.target.getAttribute("value")
        this.sortingFieldTarget.value = this.sortingCriteria
        this.dropdownTarget.classList.add("hidden")
        this.defaultSortingCriteriaTarget.textContent = e.target.textContent
        this.formTarget.requestSubmit()
    }

    toggleAscDesc() {
        if(this.ascdesc == "asc") {
            this.ascdesc = "desc"
        } else if(this.ascdesc == "desc") {
            this.ascdesc = "asc"
        }
        this.ascdescFieldTarget.value = this.ascdesc
        this.arrowTarget.classList.toggle("rotate-180")
        this.formTarget.requestSubmit()
    }
}