import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "searchInput", "searchable", "noSearchResults" ]
    
    connect() {
        this.resultsNoticeVisible = false
    }
    
    search(event) {
        let resultsCount = 0
        let lowerCaseSearchTerm = this.searchInputTarget.value.toLowerCase()
    
        this.searchableTargets.forEach((el, i) => {
            let searchableKey =  el.textContent.toLowerCase()
            if(searchableKey.includes(lowerCaseSearchTerm)) {
                resultsCount += 1
                el.classList.toggle("hidden", !searchableKey.includes(lowerCaseSearchTerm))
            }
            else if(!searchableKey.includes(lowerCaseSearchTerm)) {
                el.classList.toggle("hidden", !searchableKey.includes(lowerCaseSearchTerm))
            }
        })

        if(resultsCount == 0 && !this.resultsNoticeVisible) {
            this.searchInputTarget.insertAdjacentHTML('afterend', `<div data-target="search-dropdown.noSearchResults">Keine Resultate</div>`)
            this.resultsNoticeVisible = true
        }
        else if(resultsCount > 0 && this.resultsNoticeVisible) {
            this.noSearchResultsTarget.remove()
            this.resultsNoticeVisible = false
        }
    }
}