import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js'

export default class extends Controller {
    connect() {
      const placement = this.data.get('placement') || 'right'
      tippy(document.querySelectorAll('[data-tippy-content]'), {
        placement: placement,
        theme: 'light'
      })
    }
}