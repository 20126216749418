import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["mini", "full"]

  connect() {
    this.checkEditorContent();
  }

  show() {
    this.miniTarget.style.display = "none"
    this.fullTarget.style.display = "block"
    this.fullTarget.querySelector('trix-editor').focus()
  }

  checkEditorContent() {

    const trixInput = this.fullTarget.querySelector('trix-editor').textContent
    
    if (trixInput.length > 0) {
      // if the trix editor has content
      this.show();
    }
  }
}