import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js'

export default class extends Controller {
  static targets = [ "tooltip"]
  connect() {
    tippy(this.tooltipTarget, {
      content(reference) {
        const id = reference.getAttribute('data-template');
        const template = document.getElementById(id);
        return template.innerHTML;
      },
      allowHTML: true,
      theme: 'light'
    });    
  }
}