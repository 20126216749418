import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        this.mouseDown = false;
        this.selectedElements = [];
        this.textareaOpen = false;
    
        this.setupEventListeners()
    }

    disconnect() {
        const pasteArea = document.querySelector('#paste-area');
        if(pasteArea) pasteArea.removeEventListener('paste', this.boundHandlePaste);
        
        document.removeEventListener('mousedown', this.boundHandleMouseDown);
        
        const formContainer = document.querySelector('#form-container');
        if(formContainer) formContainer.removeEventListener('mousemove', this.boundHandleMouseMove);
        
        document.removeEventListener('mouseup', this.boundHandleMouseUp);
        document.removeEventListener('keydown', this.boundHandleKeyDown);
    }

    setupEventListeners() {
        this.boundHandlePaste = event => this.handlePaste(event);
        document.querySelector('#paste-area').addEventListener('paste', this.boundHandlePaste);


        this.boundHandleMouseDown = e => {
            this.clearSelection();
            if (e.target.nodeName === 'INPUT' || e.target.nodeName === 'TR' || e.target.nodeName === 'TD') {
                this.mouseDown = true;
                this.selectElement(e.target.closest('tr'));
            }
        }
        document.addEventListener('mousedown', this.boundHandleMouseDown);

        this.boundHandleMouseMove = e => {
            if (this.mouseDown) {
                this.selectElement(e.target.closest('tr'));
            }
        }
        document.querySelector('#form-container').addEventListener('mousemove', this.boundHandleMouseMove);

        this.boundHandleMouseUp = () => {
            this.mouseDown = false;
        }
        document.addEventListener('mouseup', this.boundHandleMouseUp);

        this.boundHandleKeyDown = e => {
            if (e.key === 'Delete' || e.key === 'Backspace') {
                this.deleteSelected();
            }
        }
        document.addEventListener('keydown', this.boundHandleKeyDown);
    }

    handlePaste(event) {
        event.preventDefault();

        let pastedData = (event.clipboardData || window.clipboardData).getData('text');
        this.parseData(pastedData);
    }

    parseData(data) {
        let rowsOfData = [];
        let currentCell = '';
        let withinQuotes = false;
        let columnCounter = 0;
        let currentRow = {};

        for (let i = 0; i < data.length; i++) {
            let char = data[i];
            let nextChar = data[i+1];

            if (char === '"') {
                if (withinQuotes && nextChar === '"') {
                    currentCell += char;
                    i++; 
                } else {
                    withinQuotes = !withinQuotes;
                }
            } else if (char === '\n' && !withinQuotes) {
                // Determine whether the current cell is a question or an answer
                if(columnCounter % 2 === 0){
                    currentRow.question = currentCell;
                } else {
                    currentRow.answer = currentCell;
                    // Only push to parsedData when both question and answer fields are filled
                    rowsOfData.push(currentRow);
                    currentRow = {};
                }
                currentCell = '';
                columnCounter = 0;   
            } else if (char === '\t' && !withinQuotes) {
                if(columnCounter % 2 === 0){
                    currentRow.question = currentCell;
                } else {
                    currentRow.answer = currentCell;
                }
                  currentCell = '';
                  columnCounter++;
            } else {
                if(withinQuotes && char === '\n') {
                  currentCell += ' '; 
                }
                currentCell += char;
            }
        }

        // Add the last row
        if (currentCell) {
            if(columnCounter % 2 === 0){
              currentRow.question = currentCell;
            } else {
              currentRow.answer = currentCell;
              rowsOfData.push(currentRow);
              currentRow = {};
            }
        }

        if(rowsOfData.length === 0) {
            alert('The pasted data is in an incorrect format. Please make sure you are pasting two columns Question | Answer from Excel.');
            document.querySelector('#paste-area').value = "Question | Answer\nQuestion | Answer\nQuestion | Answer";
            return
        }

        document.querySelector('#paste-area').remove();
        this.populateFormFields(rowsOfData);
        document.getElementById("back-button").classList.remove("hidden")
    }

    populateFormFields(rowsOfData) {
        let tableBody = document.querySelector('#questions-table tbody');
        document.getElementById("explainer").remove();
  
        rowsOfData.forEach((row, index) => {
          let tableRow = document.createElement('tr');
          tableRow.className = 'form-row';
  
          // Create cells for each data item
          let questionCell = document.createElement('td');
          questionCell.classList.add("border-b", "border-slate-100", "p-1", "pl-8", "text-slate-500");
          let questionField = document.createElement('input');
          questionField.classList.add("form-control")
          questionField.type = 'text';
          questionField.name = `questions[${index}][title]`;
          questionField.value = row.question;
          questionCell.appendChild(questionField);
          tableRow.appendChild(questionCell);
  
          let answerCell = document.createElement('td');
          answerCell.classList.add("border-b", "border-slate-100", "p-1", "pr-8", "text-slate-500");
          let answerField = document.createElement('input');
          answerField.classList.add("form-control")
          answerField.type = 'text';
          answerField.name = `questions[${index}][answer]`;
          answerField.value = row.answer;
          answerCell.appendChild(answerField);
          tableRow.appendChild(answerCell);
  
          // Append the new row to the table
          tableBody.appendChild(tableRow);
        });
        this.toggleInputTextareaOnFocusBlur();
    }


    selectElement(element) {
        if (element.nodeName === 'TR' && !this.selectedElements.includes(element)) {
          element.classList.add('selected', "bg-slate-300");
          this.selectedElements.push(element);
        }
    }
  
    clearSelection() {
        this.selectedElements.forEach(element => element.classList.remove('selected', "bg-slate-300"));
        this.selectedElements = [];
    }
  
    deleteSelected() {
        if (this.textareaOpen && this.selectedElements.length === 1) {
          return;
        } else {
            this.selectedElements.forEach(element => element.remove());
            this.selectedElements = [];
        }
    }


    toggleInputTextareaOnFocusBlur() {
        const table = document.querySelector('#questions-table');
  
        table.addEventListener('focus', (event) => {
          if (event.target.tagName.toLowerCase() === 'input') {
            const textarea = document.createElement('textarea');
            textarea.setAttribute('class', 'form-control');
            textarea.setAttribute('name', event.target.getAttribute('name'));
            textarea.value = event.target.value;
            event.target.replaceWith(textarea);
            textarea.focus();
            this.textareaOpen = true;
          }
        }, true);
  
        table.addEventListener('blur', (event) => {
          if (event.target.tagName.toLowerCase() === 'textarea') {
            const inputField = document.createElement('input');
            inputField.setAttribute('class', 'form-control');
            inputField.setAttribute('type', 'text');
            inputField.setAttribute('name', event.target.getAttribute('name'));
            inputField.value = event.target.value;
            event.target.replaceWith(inputField);
            this.textareaOpen = false;
          }
        }, true);
    }
}